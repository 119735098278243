import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const PostsPage = () => (
  <Layout>
    <SEO title="Blog Posts comming soon"/>
    <section>
      <h1>Comming soon</h1>
      <Link to="/">Go back to the homepage</Link>
    </section>
  </Layout>
)

export default PostsPage
